
<template>
  <v-row class="scroll-container ove ma-0">
    <v-container fluid fill-height class="pa-0">
      <v-row class="justify-start">
        <v-col cols="12"
          class="d-flex align-center pa-0"
        >
          <v-col
            class="text-subtitle-1 text-md-h4 pa-0"
          >
            <div 
              class="d-flex align-center box ma-auto"
              :class="{'m_box d-flex align-center' : $vuetify.breakpoint.smAndDown}"
            >
              <div
                class="icon_box d-flex justify-center"
                :class="{'mb-0' : $vuetify.breakpoint.smAndDown}"
              >
                <v-img 
                  src="@/assets/icons/instagram.png" 
                  :width="$vuetify.breakpoint.mdAndUp ? 48 : 20" 
                  :height="$vuetify.breakpoint.mdAndUp ? 48 : 20" 
                  class="mr-6"
                  @click="navigateTo('https://www.instagram.com/arccf.com_')"
                />
                <v-img 
                  src="@/assets/icons/blog.png"
                  :width="$vuetify.breakpoint.mdAndUp ? 48 : 20" 
                  :height="$vuetify.breakpoint.mdAndUp ? 45 : 18" 
                  class="mr-6"
                  @click="navigateTo('https://blog.naver.com/arckitchens')"
                />
              </div>    
            </div>
            <div 
              class="pa-0 box ma-auto"
              :class="{'m_box ma-auto' : $vuetify.breakpoint.smAndDown}"
              v-if="$vuetify.breakpoint.mdAndUp"  
            >
              <div 
                class="align-center"
                :class="{'justify-center' : $vuetify.breakpoint.smAndDown}"
              >
                <div 
                  class="mt-4 align-center d-flex"
                  :class="{'m_box' : $vuetify.breakpoint.smAndDown}"
                > 
                  <div 
                    class="text_box text-subtitle-2 text-md-h6 font-weight-bold ma-0 text-start lightly-dark-2--text"
                    :class="{'m_text' : $vuetify.breakpoint.smAndDown}"
                  >상호명</div>
                  <div class="text-subtitle-2 text-md-h6 pa-0 cursor text-decoration-underline lightly-dark-2--text" @click="navigateTo('/signin')">아크키친 공유주방 - (주)아크</div>
                </div>
                <div
                  class="mt-2 d-flex "
                  :class="{'flex-column' : $vuetify.breakpoint.smAndDown}"
                >
                  <div
                    clss="box-lag"
                    :class="{'m_box d-flex' : $vuetify.breakpoint.smAndDown}"
                  >
                    <div 
                      class="text_box text-subtitle-2 text-md-h6 font-weight-bold ma-0 pa-0 text-start lightly-dark-2--text"
                      :class="{'m_text' : $vuetify.breakpoint.smAndDown}"  
                    >주소</div> 
                    <div 
                      class="text-caption text-md-body-1 font-weight-300 ma-0 pa-0 "
                      :class="{'m_box2' : $vuetify.breakpoint.smAndDown}"
                    >
                      <a href="http://naver.me/xw6m76Sd">(본사)전라북도 전주시 완산구 홍산중앙로47, 602호</a><br/>
                      <a href="https://map.naver.com/v5/entry/place/1121396385?placePath=%2Fhome">(본사직영 A)전라북도 전주시 완산구 효자로161, 204호(A지점)</a><br/>
                      <a href="https://map.naver.com/v5/entry/place/1121396385?placePath=%2Fhome">(본사직영 B)전라북도 전주시 완산구 효자로161, 206호(B지점)</a><br/>
                    </div>  
                  </div>
                  <div 
                    class="d-flex"
                    :class="{'m_box d-flex' : $vuetify.breakpoint.smAndDown}"
                  >
                    <div 
                      class="text_box text-subtitle-2 text-md-h6 font-weight-bold ma-0 pa-0 ml-md-16 lightly-dark-2--text"
                      :class="{'m_text mt-4' : $vuetify.breakpoint.smAndDown}"  
                    >
                      <div>TEL</div>
                      <div>FAX</div>
                      <div>E-mail</div>
                    </div> 
                    <div 
                      class="text-subtitle-2 text-md-h6 ma-0 pa-0 lightly-dark-2--text"
                      :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"  
                    >
                      <div>070-8725-7777</div>
                      <div>0504-060-1999</div>
                      <div>work@modooceo.com</div>
                    </div>  
                  </div>
                  
                </div>
                <div class="mt-2 align-start d-flex lightly-dark-2--text">
                  <div 
                    class="text_box text-subtitle-2 text-md-h6 font-weight-bold ma-0 pa-0"
                    :class="{'m_text' : $vuetify.breakpoint.smAndDown}"
                  >사업자 번호</div> 
                  <div class="text-subtitle-2 text-md-h6 pa-0 font-weight-100">266-86-01845</div>
                </div>
              </div>
            </div>
          </v-col>
        </v-col>
        <div 
          class="m_box ma-auto mt-6"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <div class="text-caption mt-0">
            <div>(주)아크</div>
            <div>전라북도 전주시 완산구 홍산중앙로 47 602호</div>
          </div>
          <div class="text-caption mt-4">
            <div>사업자번호 : 266-86-01845 사업자정보확인</div>
          </div>
          <div class="text-caption mt-4">
            <div>전자금융분쟁처리</div>
            <div>대표전화 : 070-8725-7777</div>
            <div>대표메일 : work@modooceo.com</div>
          </div>
        </div>
      </v-row>
    </v-container> 
  </v-row>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return ({
      dialog: false,
      ex4:['primary']
    })
  },
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap";
@import "https://fonts.googleapis.com/css2?family=Jua&display=swap";

.scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.scroll-container {
  height: 40vh;
  background-color: #f5f9ff;
  margin: 0 !important;
  a {
    text-decoration: none;
    color: #444444;
  }
  a:hover {
    color: rgb(255, 255, 255);
  }
}
.text_start {
  margin-top: 10px;
}
.icon_box {
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}
.v-btn--icon.v-size--default {
  height: 25px;
}
.text_bottom {
  max-width: 500px;
  flex-direction: column;
  margin: 60px 20px 30px !important;
}
.v-icon {
  text-align: center;
}
.v-application .justify-space-between {
 justify-content: start !important;
}
.box {
  max-width: 1000px !important;
}
.m_box {
  width: 300px !important;
}
.text_box {
  width: 120px !important;
}
.m_text {
  width: 100px !important;
}
.m_box2 {
  width: 200px !important;
}
.box-lag {
  width: 1000px !important;
}
.ove {
  overflow-x: hidden;
}

</style>